import request from '@/utils/request'

//登录获取token
export function loginGetToken(data: LoginData) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: {
      username: data.username,
      password: data.password,
    },
  })
}

//素材标签列表
export function getTagList(params) {
  return request({
    url: '/label/list',
    method: 'get',
    params,
  })
}

//Delete标签列表
export function deleteLabel(params) {
  return request({
    url: '/label/delete/'+params,
    method: 'post'
  })
}

//素材列表 /material/list
export function materialList(params) {
  return request({
    url: '/material/list',
    method: 'get',
    params,
  })
}

//素材列表不分页 /material/listAll
export function materialListAll(params) {
  return request({
    url: '/material/listAll',
    method: 'get',
    params,
  })
}

//素材列表Delete /material/delete/{id}
export function materialDelete(data) {
  return request({
    url: '/material/delete/'+ data,
    method: 'post',
  })
}


//素材标签列表保存 /label/save
export function saveTag(data) {
  return request({
    url: '/label/save',
    method: 'post',
    data,
  })
}

//素材列表根据id获取详情 /material/findById/{labelId}
export function getMaterialDetail(params) {
  return request({
    url: '/material/findById/' + params,
    method: 'get',
  })
}


//素材标签列表不分页
export function getTagListAll(params) {
  return request({
    url: '/label/listAll',
    method: 'get',
    params
  })
}

//用户列表新增
export function registerUser(data) {
  return request({
    url: '/admin/register',
    method: 'post',
    data,
  })
}

//用户列表编辑
export function editUser(data) {
  return request({
    url: '/admin/update/' + data.id,
    method: 'post',
    data,
  })
}

//店铺关联账号 
export function editUserByid(data) {
  return request({
    url: '/admin/updateById',
    method: 'post',
    data,
  })
}

//用户列表
export function getUserList(params) {
  return request({
    url: '/admin/list',
    method: 'get',
    params,
  })
}

//用户列表Delete /admin/delete/{id}
export function deleteUser(data) {
  return request({
    url: '/admin/delete/' + data,
    method: 'post',
  })
}

//菜单列表
export function fetchList(parentId, params) {
  return request({
    url: '/menu/list/' + parentId,
    method: 'get',
    params: params,
  })
}

//更新是否开启
export function updateHidden(id, params) {
  return request({
    url: '/menu/updateHidden/' + id,
    method: 'post',
    params: params,
  })
}

//Delete菜单
export function deleteMenu(id) {
  return request({
    url: '/menu/delete/' + id,
    method: 'post',
  })
}

//新增菜单
export function createMenu(data) {
  return request({
    url: '/menu/create',
    method: 'post',
    data: data,
  })
}
//编辑菜单
export function updateMenu(id, data) {
  return request({
    url: '/menu/update/' + id,
    method: 'post',
    data: data,
  })
}
//获取Second Class菜单
export function getMenu(id) {
  return request({
    url: '/menu/' + id,
    method: 'get',
  })
}

//角色列表
export function roleList(params) {
  return request({
    url: '/role/list',
    method: 'get',
    params: params,
  })
}

export function createRole(data) {
  return request({
    url: '/role/create',
    method: 'post',
    data: data,
  })
}

export function updateRole(id, data) {
  return request({
    url: '/role/update/' + id,
    method: 'post',
    data: data,
  })
}

export function updateStatus(id, params) {
  return request({
    url: '/role/updateStatus/' + id,
    method: 'post',
    params: params,
  })
}

export function fetchAllRoleList() {
  return request({
    url: '/role/listAll',
    method: 'get',
  })
}

export function deleteRole(data) {
  return request({
    url: '/role/delete?ids='+data,
    method: 'post',
  })
}
export function allocRole(data) {
  return request({
    url: '/admin/role/update?roleIds='+data.roleIds+"&adminId="+data.adminId,
    method: 'post',
  })
}

//店铺管理列表所有
export function shopList(params) {
  return request({
    url: '/shop/list',
    method: 'get',
    params
  })
}

//店铺管理编辑
export function shopEdit(data) {
  return request({
    url: '/shop/save',
    method: 'post',
    data
  })
}

//店铺管理Delete
export function deleteShop(id) {
  return request({
    url: '/shop/delete/' + id,
    method: 'post',
  })
}

//获取所有 /shop/listAll
export function shopListAll() {
  return request({
    url: '/shop/listAll',
    method: 'get',
  })
}


//终端列表 /terminal/list
export function terminalList(params) {
  return request({
    url: '/terminal/list',
    method: 'get',
    params
  })
}

//终端列表编辑
export function terminalEdit(data) {
  return request({
    url: '/terminal/save',
    method: 'post',
    data
  })
}

//店铺绑定终端 /terminal/bindingShop/
export function bindingShop(id,data) {
  return request({
    url: '/terminal/bindingShop?oldTerminalId='+id,
    method: 'post',
    data
  })
}

//Cancel店铺绑定终端
export function updateShopTerminal(id) {
  return request({
    url: '/terminal/updateShopTerminalStatus/'+id,
    method: 'post',
  })
}

//终端列表Delete /terminal/delete/
export function terminaldelete(data) {
  return request({
    url: '/terminal/delete/'+data.id,
    method: 'delete',
  })
}


//店铺关联终端 /terminal/shopTerminalList
export function shopTerminalList(params) {
  return request({
    url: '/terminal/shopTerminalList',
    method: 'get',
    params
  })
}

//终端列表不分页 /terminal/listAll
export function terminalListAll(params) {
  return request({
    url: '/terminal/listAll',
    method: 'get',
    params
  })
}

//店铺关联账号 /admin/shopAdminList
export function shopAdminList(params) {
  return request({
    url: '/admin/shopAdminList',
    method: 'get',
    params
  })
}

//店铺关联账号编辑 /admin/bindingShop
export function editBindIngShop(id,data) {
  return request({
    url: '/admin/bindingShop?oldAdminId='+id,
    method: 'post',
    data
  })
}

//Cancel店铺关联账号 /admin/updateShopAdminStatus/
export function updateShopAdminStatus(id) {
  return request({
    url: '/admin/updateShopAdminStatus/'+id,
    method: 'post',
  })
}


//获取所有用户 /admin/listAll
export function adminListAll(params) {
  return request({
    url: '/admin/listAll',
    method: 'get',
    params
  })
}

//主题根据id 获取详情 /theme/findById/{themeId}
export function getThemeDetail(params) {
  return request({
    url: '/theme/findById/'+params,
    method: 'get'
  })
}


//终端关联主题 /release/list
export function releaseList(params) {
  return request({
    url: '/release/list',
    method: 'get',
    params
  })
}

//终端关联主题编辑 /release/save
export function releaseSave(data) {
  return request({
    url: '/release/save',
    method: 'post',
    data
  })
}

//终端关联主题Delete /release/delete/
export function releaseDelete(id) {
  return request({
    url: '/release/delete/'+id,
    method: 'post'
  })
}


//主题列表不分页 /theme/listAll
export function themeListAll(params) {
  return request({
    url: '/theme/listAll',
    method: 'get',
    params
  })
}
//主题列表 /theme/list
export function themeList(params) {
    return request({
      url: '/theme/list',
      method: 'get',
      params
    })
}

//主题列表新增 /theme/save
export function themeSave(data) {
  return request({
    url: '/theme/save',
    method: 'post',
    data:data
  })
}

//主题列表Delete /theme/delete/{id}
export function themeDelete(data) {
  return request({
    url: '/theme/delete/'+ data,
    method: 'post'
  })
}

//主题根据id查询



//获取所有的菜单 /menu/treeList
export function getTreeList() {
  return request({
    url: '/menu/treeList',
    method: 'get',
  })
}

export function allocMenu(data) {
  return request({
    url: '/role/allocMenu',
    method: 'post',
    data:data
  })
}

// 获取当前登录用户信息 /admin/info
export function getCurrentUser() {
  return request({
    url: '/admin/info',
    method: 'get',
  })
}

//获取oss信息 /oss/policy
export function getossPolicy() {
  return request({
    url: '/oss/policy',
    method: 'get',
  })
}

//上传素材保存  /material/save
export function materialsave(data) {
  return request({
    url: '/material/save',
    method: 'post',
    data:data
  })
}

//oss上传文件 素材专用 /oss/uploadFile
export function uploadFile(data) {
  return request({
    url: '/oss/uploadFile',
    method: 'post',
    data:data,
    headers: {
      'Content-Type': 'multipart/form-data' // 设置正确的 Content-Type
    }
  })
}
