<template>
  <VaLayout
    :top="{ fixed: true, order: 2 }"
    :left="{ fixed: true, absolute: breakpoints.mdDown, order: 1, overlay: breakpoints.mdDown && !isSidebarMinimized }"
    @leftOverlayClick="isSidebarMinimized = true"
  >
    <template #top>
      <AppNavbar :is-mobile="isMobile" />
    </template>

    <template #left>
      <AppSidebar :minimized="isSidebarMinimized" :animated="!isMobile" :mobile="isMobile" :roleType="roleType" v-if='isChildMounted' />
    </template>

    <template #content>
      <div :class="{ minimized: isSidebarMinimized }" class="app-layout__sidebar-wrapper">
        <div v-if="isFullScreenSidebar" class="flex justify-end">
          <VaButton class="px-4 py-4" icon="md_close" preset="plain" @click="onCloseSidebarButtonClick" />
        </div>
      </div>
      <AppLayoutNavigation v-if="!isMobile" class="p-4" />
      <main class="p-4 pt-0">
        <article>
          <RouterView />
        </article>
      </main>
    </template>
  </VaLayout>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed,getCurrentInstance } from 'vue'
import { storeToRefs } from 'pinia'
import { onBeforeRouteUpdate } from 'vue-router'
import { useBreakpoint } from 'vuestic-ui'

import { useGlobalStore } from '../stores/global-store'

import AppLayoutNavigation from '../components/app-layout-navigation/AppLayoutNavigation.vue'
import AppNavbar from '../components/navbar/AppNavbar.vue'
import AppSidebar from '../components/sidebar/AppSidebar.vue'
import { getCurrentUser } from '@/api/index'

const GlobalStore = useGlobalStore()
const { proxy } = getCurrentInstance();

const breakpoints = useBreakpoint()

const sidebarWidth = ref('16rem')
const sidebarMinimizedWidth = ref(undefined)

const isMobile = ref(false)
const isTablet = ref(false)
const isChildMounted = ref(false)
const { isSidebarMinimized } = storeToRefs(GlobalStore)
let roleType = ref(null)

const onResize = () => {
  isSidebarMinimized.value = breakpoints.mdDown
  isMobile.value = breakpoints.smDown
  isTablet.value = breakpoints.mdDown
  sidebarMinimizedWidth.value = isMobile.value ? '0' : '4.5rem'
  sidebarWidth.value = isTablet.value ? '100%' : '16rem'
}

const getUserInfo = () => {
  getCurrentUser().then(res => {
    if(res.code == 200) {
      let obj = res.data
      let Platformmanager = proxy.$Platformmanager
      let Storemanager = proxy.$Storemanager
      let Storeuser = proxy.$Storeuser
      sessionStorage.setItem('userInfo', JSON.stringify(obj))
      sessionStorage.setItem('shopId', obj.shop.shopId)
      let roleId
      if(obj.roleId) {
        sessionStorage.setItem('roleId', obj.roleId.split(',')[0])
        roleId = obj.roleId.split(',')[0]
      } else {
       sessionStorage.setItem('roleId','')
      }
      //1 平台管理员 2 店铺管理员 3 店铺成员
      if (Platformmanager.includes(roleId)) {
        roleType.value = 1
      } else if (Storemanager.includes(roleId)) {
        roleType.value = 2
      } else if (Storeuser.includes(roleId)) {
        roleType.value = 3
      }
      isChildMounted.value = true

    }
  })
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
  getUserInfo()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    isSidebarMinimized.value = true
  }
})

const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

const onCloseSidebarButtonClick = () => {
  isSidebarMinimized.value = true
}
</script>

<style lang="scss" scoped>
// Prevent icon jump on animation
.va-sidebar {
  width: unset !important;
  min-width: unset !important;
}
</style>
